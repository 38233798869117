import { Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Layout from '../../components/Layout';
import PageTitle from '../../components/PageTitle';
import SEO from '../../components/seo';
import Section from '../../components/Section';
import { SubscriptionForm } from '../../components/SubscriptionForm';
import YoutubePlayer from '../../components/YoutubePlayer';
import { graphql } from 'gatsby';

export default (props) => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props;

  const [showDescription, setShowDescription] = useState(false);
  return (
    <>
      <Layout location={location} title={title} fullWidth>
        <SEO
          title={'Trackerion'}
          keywords={[`pomodoro`, `technique`, `personal experiecen`]}
        />
        <Section>
          <PageTitle>
            Trackerion — Rapid Journaling featuring Awareness Score
          </PageTitle>
          <Typography>The fastest alternative to writing a journal.</Typography>
        </Section>
        <Divider />
        <YoutubePlayer id={'I8FoQI0wACI'} />

        <Section>
          <Typography variant={'h3'} style={{ fontWeight: 'bold' }}>
            Available Now
          </Typography>
          <div
            style={{
              display: 'flex',
            }}
          >
            <a
              target={'_blank'}
              href={
                'https://play.google.com/store/apps/details?id=com.trackerion&hl=en'
              }
            >
              <img
                style={{
                  marginTop: '20px',
                  width: '240px',
                }}
                src={
                  'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                }
              />
            </a>
          </div>
        </Section>

        <Section title={'JOIN'} color={'blue'}>
          <SubscriptionForm />
        </Section>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
